/** @jsx jsx */
import { format } from "date-fns";
import { Link, graphql } from "gatsby";
import React from "react";
import rehypeReact from "rehype-react";
import { Box, Container, Heading, Text, jsx } from "theme-ui";
import HexagonList from "../../components/HexagonList";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

export default function Component({ data }) {
  const {
    markdownRemark: {
      frontmatter: {
        cover,
        coverAlt,
        title,
        publishedDate,
        metaTitle,
        metaDescription,
      },
      htmlAst,
    },
  } = data;

  const ast = htmlAst;

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      img: (props) => {
        return (
          <img
            height={500}
            loading="lazy"
            width={1200}
            src={props.src}
            alt={props.alt ? props.alt : ""}
          />
        );
      },
    },
  }).Compiler;

  let url = null;

  if (typeof window !== "undefined") {
    url = window.location.origin;
  }

  return (
    <Layout
      contactBackground={data.ContactBackground.childImageSharp.gatsbyImageData}
      headerBannerText="blogs"
      headerBgImage={data.HeroImage.childImageSharp.gatsbyImageData}
      headerFancyBanner={false}
      headerSmallHero={true}
    >
      <Seo
        title={metaTitle}
        description={metaDescription}
        publishedDate={publishedDate}
        image={`${url}${cover}`}
        imageAlt={coverAlt || title}
        imageWidth={1200}
        imageHeight={500}
        type="article"
      />

      <Container
        as="article"
        sx={{
          marginBlock: 8,
        }}
      >
        <Link
          to="/blog"
          sx={{
            color: "secondary",
            cursor: "pointer",
            display: "inline-block",
            fontFamily: "body",
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "25px",
            marginBlockEnd: 4,
            textDecoration: "none",
            ":hover": {
              textDecoration: "underline",
            },
          }}
        >
          {"< "}
          Back to Blog
        </Link>
        <img
          src={cover}
          alt={coverAlt || title}
          loading="lazy"
          width={1200}
          height={500}
          sx={{
            objectFit: "cover",
            height: ["250px", null, "500px"],
            width: "100%",
            verticalAlign: "middle",
            marginBlockEnd: 7,
          }}
        />

        <Heading as="h1" variant="styles.fancyUnderline">
          {title}
        </Heading>

        <Box
          sx={{
            marginBlockStart: 4,
            marginBlockEnd: 8,
          }}
        >
          <Text
            sx={{
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "25px",
              color: "text",
              textTransform: "capitalize",
            }}
          >
            {format(new Date(publishedDate), "MMMM dd, yyyy")}
            {/* {data.strapiArticle.publishDate} */}
          </Text>
        </Box>

        <Box
          sx={{
            img: {
              maxWidth: "100%",
              height: "auto",
            },
          }}
        >
          {renderAst(ast)}
        </Box>
      </Container>

      <HexagonList
        bannerText="roof repair made easy."
        linkText="Get Started"
        linkURL="/contact"
      />
    </Layout>
  );
}

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.

export const query = graphql`
  query ($id: String) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt
      htmlAst
      frontmatter {
        title
        cover
        publishedDate
        coverAlt
        metaDescription
        metaTitle
      }
    }
    HeroImage: file(relativePath: { eq: "blog-hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    ContactBackground: file(relativePath: { eq: "footer-bg.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          webpOptions: { quality: 90 }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;
